import React from 'react';
import Layout from '../components/Layout';
import Card from '../components/Card';
import { graphql } from 'gatsby';
import createSlug from '../utils/createSlug';
import Seo from '../components/Seo';

function Articles(props) {

    const data = props.data.allMarkdownRemark.edges

    return (
        <Layout>
            <Seo title="Articles" />

            <h1>Articles</h1>

            {data.map(a => <Card
                key={`card-${a.node.fields.slug}`}
                slug={a.node.fields.slug}
                title={a.node.frontmatter.title}
                date={new Date(a.node.frontmatter.date)}
                content={a.node.excerpt}
                categories={a.node.frontmatter.categories?.map(a => {
                    return {
                        title: a,
                        slug: createSlug(a)
                    }
                })}
                tags={a.node.frontmatter.tags?.map(a => {
                    return {
                        title: a,
                        slug: createSlug(a)
                    }
                })}
            />)}

            {/* <pre>
                {JSON.stringify(data, null, 2)}
            </pre> */}

        </Layout>
    );
}

export default Articles;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
        sort: {fields: [frontmatter___date], order: DESC}
        filter: {fields: {sourceName: {eq: "pages"}}}
    ) {
        edges {
        node {
            fields {
            slug
            sourceName
            }
            excerpt(pruneLength: 500)
            frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            categories
            tags
            }
        }
        }
    }
}
`